import React from 'react'

function ServicesPageComp() {
  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 text-center p-4">
        <h1 className="display-2 text-white fw-semibold">Commercial and Residential Services</h1>
        <h1 className="fs-2 text-white fw-semibold">We Can Lift Anything Up to 40 Tons / 80,000 Pounds</h1>
        <hr className="page-line" />
        <div className="text-white text-start fs-5">
            <ul>
                <li>H.V.A.C.</li>
                <li>Pools</li>
                <li>Timber Framing</li>
                <li>Log Homes</li>
                <li>Steel Erection</li>
                <li>Tree Removal</li>
                <li>Roof Trusses</li>
                <li>Shipping Containers</li>
                <li>Much More</li>
            </ul>
        </div>
        
      </div>
    </>
  )
}

export default ServicesPageComp