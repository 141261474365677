import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./css/App.css";

//Main Comp Imports
import HeaderComp from "./Components/HeaderComp";
import NavComp from "./Components/NavComp";
//Pages Comp Imports
import HomePageComp from "./Components/pages/HomePageComp";
import AboutPageComp from "./Components/pages/AboutPageComp";
import SafetyPageComp from "./Components/pages/SafetyPageComp";
import WhatWeOfferPageComp from "./Components/pages/WhatWeOfferPageComp";
import ServicesPageComp from "./Components/pages/ServicesPageComp";
import FleetSpecsPageComp from "./Components/pages/FleetSpecsPageComp";
import QuotePageComp from "./Components/pages/QuotePageComp";
import ContactPageComp from "./Components/pages/ContactPageComp";
import FooterComp from "./Components/FooterComp";
import DisclaimerComp from "./Components/DisclaimerComp";

function App() {
  useEffect(() => {
    try {
      var myModal = document.querySelector(".modal-backdrop");
      var pageBody = document.querySelector("body");
      pageBody.classList.remove("modal-open");
      pageBody.style.removeProperty("overflow");
      pageBody.style.removeProperty("padding-right");
      myModal.remove();
      console.log("modal Closed");
    } catch (error) {
      console.log("modal Close Failed");
    }
  }, [useLocation()]);

  return (
    <>
      <div className="app-bg min-vh-100">
        <DisclaimerComp />
        <HeaderComp />
        <NavComp />
        <div className="p-3">
          <Routes>
            <Route path="/" element={<HomePageComp />} />
            <Route path="/AboutUs" element={<AboutPageComp />} />
            <Route path="/Safety" element={<SafetyPageComp />} />
            <Route path="/WhatWeOffer" element={<WhatWeOfferPageComp />} />
            <Route path="/Services" element={<ServicesPageComp />} />
            <Route path="/FleetSpecs" element={<FleetSpecsPageComp />} />
            <Route path="/Quote" element={<QuotePageComp />} />
            <Route path="/ContactUs" element={<ContactPageComp />} />
          </Routes>
        </div>
        <FooterComp />
      </div>
    </>
  );
}

export default App;
