import React from 'react'

function QuotePageComp() {
  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 text-center p-4">
        <h1 className="display-2 text-white fw-semibold">Get a Crane Quote</h1>
        <hr className="page-line" />



        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            {" "}
            <p className="fs-5 text-white m-1 Text-shadow-1">
              Phone#:{" "}
              <a className="link-info" href="tel:3142969040">
                314-296-9040
              </a>
            </p>
          </div>
          <div className="col-12 col-lg-6">
            <p className="fs-5 text-white m-1 Text-shadow-1">
              Email:{" "}
              <a className="link-info" href="mailto:Ben@StevensCrane.com">
                Ben@StevensCrane.com
              </a>
            </p>
          </div>
        </div>
        <h2 className="fw-2 text-white">Please Include Items Below:</h2>
        <p className="text-light">A + C: Total Distance From Base of Crane</p>
        <p className="text-light">B: Height of Obstacles</p>
        <p className="text-white">Total Object Weight</p>
        <img className='img-fluid' src="./images/quotepic.jpg" alt="" />
      </div>
    </>
  )
}

export default QuotePageComp