import React from 'react'

function ContactPageComp() {
  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 text-center p-4">
        <h1 className="display-2 text-white fw-semibold">About Us</h1>
        <hr className="page-line" />
        <h1 className="display-5 text-white fw-semibold Text-shadow-1 ">
          Contact Info
        </h1>
        <p className="fs-4 text-light fw-light Text-shadow-1">(Contact for a Quote)</p>
        <p className="fs-3 text-white m-1 Text-shadow-1">
          President: <span className="text-info">
          {/* Ben Stevens */}
          John Doe
          </span>
        </p>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            {" "}
            <p className="fs-5 text-white m-1 Text-shadow-1">
              Phone#:{" "}
              <a className="link-info" href="tel:5555555555">
                555-555-5555
              </a>
            </p>
          </div>
          <div className="col-12 col-lg-6">
            <p className="fs-5 text-white m-1 Text-shadow-1">
              Email:{" "}
              <a className="link-info" href="mailto:example@exam.com">
                example@example.com
              </a>
            </p>
          </div>
          <div className="col-12 col-lg-6">
            <p className="fs-5 text-white m-1 Text-shadow-1">
              Address:{" "}
              <a className="link-info" href="geo:0">
                {/* 3961 W Four Ridge Rd, House Springs MO, 63051  38.430827279581585,-90.52347858647052*/}
              </a>
            </p>
          </div>
        </div>
        <hr className="page-line" />
      </div>
    </>
  )
}

export default ContactPageComp