import React from "react";
import { useState, useEffect } from "react";
import "../../css/imageSlider.css";
function ImageSliderComp(props) {
  const imagesList = [
    "./images/slider/slider1.jpg",
    "./images/slider/slider2.jpg",
    "./images/slider/slider3.jpg",
    "./images/slider/slider4.jpg",
    "./images/slider/slider5.jpg",
    "./images/slider/slider6.jpg",
  ];
  let [currentImg, setCurrentImg] = useState(imagesList[0]);
  let picIndex = 0;
  let [flipClass, setflipClass] = useState("slit-in-vertical");
  useEffect(() => {
    const timer = setInterval(() => {
      setflipClass("fade-out");

      picIndex++;

      if (picIndex === imagesList.length) {
        picIndex = 0;
      }
      console.log(picIndex);

      setTimeout(() => {
        setCurrentImg(imagesList[picIndex]);

        setflipClass("fade-in");
      }, 1500);
    }, 4000);
    return () => clearInterval(timer);
  }, [picIndex]);

  return (
    <>
      <div className={`row justify-content-center m-3 ${flipClass}`}>
        <div
          className="col-12 slider mh-100"
          style={{ backgroundImage: "url(" + currentImg + ")" }}
        ></div>
      </div>
    </>
  );
}

export default ImageSliderComp;
