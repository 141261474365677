import React from 'react'

function SafetyPageComp() {
  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 text-center p-4">
        <h1 className="display-2 text-white fw-semibold">Safety</h1>
        <h1 className="fs-2 text-white fw-normal">All of our operators Maintain these certifications</h1>
        <hr className="page-line" />
        <div className="row gy-2">
          <div className="col-6 col-lg-4 text-white">N.C.C.O</div>
          <div className="col-6 col-lg-4 text-white">OSHA</div>
          <div className="col-6 col-lg-4 text-white">MSHA</div>
          <div className="col-6 col-lg-4 text-white">Qualified Signal Person</div>
          <div className="col-6 col-lg-4 text-white">Qualified Rigger</div>
          <div className="col-6 col-lg-4 text-white">First Aid / CPR Trained</div>
        </div>
        <div className="row mt-5 gy-2">
          <div className="col-12 col-lg-4">
            <img
              className="img-1 slide-fwd-center box-shadow"
              src="./images/cco.jpg"
              alt="CCO"
            />
          </div>
          <div className="col-12 col-lg-4 ">
            <img
              className="img-1 slide-fwd-center box-shadow"
              src="./images/MSHA.jpg"
              alt="MSHA"
            />
          </div>
          <div className="col-12 col-lg-4 ">
            <img
              className="img-1 slide-fwd-center box-shadow"
              src="./images/osha.jpg"
              alt="OSHA"
            />
          </div>
        </div>
        
      </div>
    </>
  )
}

export default SafetyPageComp