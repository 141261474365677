import React from 'react'

function FooterComp() {
  return (
    <div className='bg-dark text-white'>
        <div className="container">
            <div className="row justify-content-center text-center">
                <div className="col-6">&copy;Stevens Crane Service L.L.C.</div>
                <div className="col-6">Website Designed & managed by: Jacob Weinrich</div>
            </div>
        </div>
        

    </div>
  )
}

export default FooterComp