function DisclaimerComp() {
  return (
    <>
      <div className="bg-danger text-center">
        <span className="fs-2 text-white fw-bold">This website is a copy of a website thats no longer in use!</span>
      </div>
    </>
  );
}

export default DisclaimerComp;
