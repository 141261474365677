import React from "react";

function AboutPageComp() {
  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 text-center p-4">
        <h1 className="display-2 text-white fw-semibold">About Us</h1>
        <hr className="page-line" />
        <p className="fs-4 text-light Text-shadow-1 m-2 mt-3 fw-light">
          <span className="fw-semibold">Stevens Crane LLC</span> is a respected
          <span className="fw-semibold text-warning">
            {" "}
            Family Owned Company{" "}
          </span>
          that is in the crane rental, storage and transportation business. We
          take pride in providing the best service and rigging products for all
          your construction lifting needs. Whether you need to have a crane on
          the job tomorrow, or just comparing prices for future projects,{" "}
          <span className="fw-semibold">Stevens Crane LLC</span> is here to help
          and we guarantee that you will be provided with the highest degree of
          service and professionalism. Our experienced,{" "}
          <span className="fw-semibold text-warning">
            NCCO certified, IUOE Local 513 operators
          </span>{" "}
          will surely complete your hoisting project safely and productively.
        </p>
        <hr className="page-line" />
      </div>
    </>
  );
}

export default AboutPageComp;
