import React from "react";

function FleetSpecsPageComp() {
  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 text-center p-4">
        <h1 className="display-2 text-white fw-semibold">Fleet Specs</h1>
        <hr className="page-line" />
        <div className="row justify-content-center m-4">
          <div className="col-12 col-lg-6">
            <a className="text-warning text-decoration-none fw-normal" href="#chart1">
              40 Ton Truck Crane
            </a>
          </div>
          <div className="col-12 col-lg-6">
            <a className="text-warning text-decoration-none fw-normal" href="#chart2">
              12 1/2 Ton Boom Truck
            </a>
          </div>
          <hr className="page-line mt-2" />
          <h1 id="chart1" className="fs-2 text-white">40 Ton Truck Crane</h1>
          <p className="fs-4 text-white">$220/Hour Portal to Portal, 4hr Min  </p>
          <a target={"_blank"} className="text-warning text-decoration-none fw-normal" href="./images/ats540-full.pdf">
              Full Chart Download
            </a>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6"><img className="img-fluid " src="./images/40tonpic1.png" alt="" /></div>
              <div className="col-12 col-lg-6">  <img className="img-fluid " src="./images/40tonpic2.png" alt="" /></div>

            </div>
            
          

          {/* <iframe className="min-vh-100" src="./images/ats540.pdf" frameborder="0"></iframe> */}
          <hr className="page-line mt-2" />
          <h1 id="chart2" className="fs-2 text-white">12 1/2 Ton Boom Truck</h1>
          <p className="fs-4 text-white">$150/Hour Portal to Portal, 2hr Min  </p>
          <a target={"_blank"} className="text-warning text-decoration-none fw-normal" href="./images/BoomTruck.pdf">
              Full Chart Download
            </a>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6">
                  <img className="img-fluid w-100" src="./images/boomtruckpic1.png" alt="" />
              </div>
            </div>
          
          {/* <iframe  className="min-vh-100" src="./images/BoomTruck.pdf" frameborder="0"></iframe> */}
        </div>

        <hr className="page-line" />
      </div>
    </>
  );
}

export default FleetSpecsPageComp;
