import React from "react";
import "../../css/App.css";
import { MdMiscellaneousServices } from "react-icons/md";
import { IoIosConstruct } from "react-icons/io";
import ImageSliderComp from "../pageParts/ImageSliderComp";

function HomePageComp() {
  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 text-center p-4">
        <h1 className="display-3 text-warning fw-semibold Text-shadow-1">
          Taking Your Project To New Heights
        </h1>
        
        <h1 className="display-3 text-white fw-semibold Text-shadow-1">
          Operated Crane Rentals
        </h1>
        <hr className="page-line" />
        <ImageSliderComp />
        <hr className="page-line" />
        <h1 className="display-5 text-white fw-semibold Text-shadow-1 ">
          Contact Info
        </h1>
        <p className="fs-4 text-light fw-light Text-shadow-1">(Contact for a Quote)</p>
        <p className="fs-3 text-white m-1 Text-shadow-1">
          President: <span className="text-info">
          {/* Ben Stevens */}
          John Doe
          </span>
        </p>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            {" "}
            <p className="fs-5 text-white m-1 Text-shadow-1">
              Phone#:{" "}
              <a className="link-info" href="tel:555-555-5555">
                {/* 314-296-9040  tel:3142969040*/}
                555-555-5555
              </a>
            </p>
          </div>
          <div className="col-12 col-lg-6">
            <p className="fs-5 text-white m-1 Text-shadow-1">
              Email:{" "}
              <a className="link-info" href="mailto:example@example.com">
                {/* Ben@StevensCrane.com */}
                Example@example.com
              </a>
            </p>
          </div>
        </div>
        <p className="fs-2 fw-semibold text-light Text-shadow-1 vibrate-1">
          <MdMiscellaneousServices /> What We Offer <MdMiscellaneousServices />
        </p>
        <div className="row justify-content-center">
          <div className="col-6 col-lg-4">
            <p className="fs-5 fw-light text-light Text-shadow-1">
              24/7 Emergency Service
            </p>
          </div>
          <div className="col-6 col-lg-4">
            <p className="fs-5 fw-light text-light Text-shadow-1">
              Expert Rigging of any Equipment
            </p>
          </div>
        
        </div>

        <p className="fs-2 fw-semibold text-light Text-shadow-1 vibrate-1">
          <MdMiscellaneousServices /> Resident & Commercial Crane Services{" "}
          <MdMiscellaneousServices />
        </p>
        <div className="row justify-content-center">
          <div className="col-6 col-lg-4">
            <p className="fs-5 fw-light text-light Text-shadow-1">Pools</p>
          </div>
          <div className="col-6 col-lg-4">
            <p className="fs-5 fw-light text-light Text-shadow-1">
              H.V.A.C.
            </p>
          </div>
          <div className="col-6 col-lg-4">
            <p className="fs-5 fw-light text-light Text-shadow-1">
              Timber Framing
            </p>
          </div>
          <div className="col-6 col-lg-4">
            <p className="fs-5 fw-light text-light Text-shadow-1">
              Log Homes
            </p>
          </div>
          <div className="col-6 col-lg-4">
            <p className="fs-5 fw-light text-light Text-shadow-1">
              Tree Removal
            </p>
          </div>
        </div>

        

        <div className="row mt-5 gy-2">
          <div className="col-12 col-lg-4">
            <img
              className="img-1 slide-fwd-center box-shadow"
              src="./images/cco.jpg"
              alt="CCO"
            />
          </div>
          <div className="col-12 col-lg-4 ">
            <img
              className="img-1 slide-fwd-center box-shadow"
              src="./images/MSHA.jpg"
              alt="MSHA"
            />
          </div>
          <div className="col-12 col-lg-4 ">
            <img
              className="img-1 slide-fwd-center box-shadow"
              src="./images/osha.jpg"
              alt="OSHA"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePageComp;
