import React from "react";
import { NavLink } from "react-router-dom";
import "../css/navbar.css";

function NavComp() {
  return (
    <>
      <nav className="navbar navbar-expand-lg position-sticky top-0 bg-stone-1 navbar-dark border-top rounded-bottom-4 border-warning">
        <div className="container-fluid">
          <NavLink className={"navbar-brand fw-bold"} to={"/"}>
            <img className="nav-img" src="#" alt="" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className={"nav-link fw-semibold"} to={"/"}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={"nav-link fw-semibold"} to={"/ContactUs"}>
                  Contact Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={"nav-link fw-semibold"} to={"/AboutUs"}>
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={"nav-link fw-semibold"} to={"/Safety"}>
                  Safety
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={"nav-link fw-semibold"} to={"/WhatWeOffer"}>
                  What We Offer
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={"nav-link fw-semibold"} to={"/Services"}>
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={"nav-link fw-semibold"} to={"/FleetSpecs"}>
                  Fleet Specs / Rates
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={"nav-link fw-semibold"} to={"/Quote"}>
                  Crane Quote
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavComp;
