import React from 'react'

function WhatWeOfferPageComp() {
  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 text-center p-4">
        <h1 className="display-2 text-white fw-semibold">What We Offer</h1>
        <hr className="page-line" />
        <div className="text-white text-start fs-5">
            <ul>
                <li>Experienced N.C.C.O. Certified I.U.O.E. 513 Operators</li>
                <li>Expert Rigging of any Equipment</li>
                <li>Well Maintained Fleet of Cranes</li>
                <li>Osha and MSHA Compliant</li>
                <li>Certified & Tested Rigging</li>
                <li>Free Job Site Analysis & Estimates</li>
                <li>24/7 Emergency Service</li>
                <li>UnLoading & Short Term Lot Storage</li>
                <li>Most Competitive Rates</li>
            </ul>
        </div>
    
        
      </div>
    </>
  )
}

export default WhatWeOfferPageComp